import React, { useEffect, useState } from 'react';

import logo from './logo.svg'

function App() {
  const [url, setUrl] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(`trustlines:/${window.location.pathname}${window.location.search}`)
    }
  }, [])

  const openLink = link => {
    window.location = link
  }


  return (
    <div className="app-container">
      <div />
      <div className="logo-container">
        <img className="logo" src={logo} alt="logo" />
        <div className="link-container">
          {window.location.pathname !== '/' && (
            <button
              className="open-btn"
              onClick={() => openLink(url)}
            >
              Open link in the app
            </button>
          )}
          <button
            className="open-btn"
            onClick={() => openLink('https://trustlines.app')}
          >
            Get the app
          </button>
        </div>
      </div>
      <div className="footer">
        <a href="https://trustlines.app/#/imprint-privacy-policy">
          Imprint & Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default App;
